<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar
      @menu-toggle="onMenuToggle"
      :sidebarVisible="isSidebarVisible()"
    />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      <div class="flex justify-content-end">
        <button
          class="p-link layout-menu-button layout-topbar-button"
          @click="onMenuToggle"
        >
          <font-awesome-icon
            :icon="
              isSidebarVisible() ? 'circle-arrow-left' : 'circle-arrow-right'
            "
            size="2x"
            fixed-width
            class="icon"
          />
        </button>
      </div>
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <ConfirmDialog />
        <Toast />
        <router-view />
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";

import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";

import { features } from "@/features";

export default {
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    };
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle(event) {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) {
        element.classList.add(className);
      } else {
        element.className += " " + className;
      }
    },
    removeClass(element, className) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") {
          return !this.staticMenuInactive;
        } else if (this.layoutMode === "overlay") {
          return this.overlayMenuActive;
        }
      } else {
        return this.mobileMenuActive;
      }
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
          "layout-theme-light": this.$appState.theme.startsWith("saga"),
        },
      ];
    },
    menu() {
      let menuItems = [];

      menuItems.push({
        label: "Product Trends",
        icon: "crosshairs",
        to: { name: "ProductTrends" },
        feature: features.BASIC,
      });
      menuItems.push({
        label: "Niche Trends",
        icon: "arrow-trend-up",
        to: { name: "NicheTrends" },
        feature: features.BASIC,
      });
      menuItems.push({
        label: "Niche Analysis",
        icon: "chart-pie",
        to: { name: "NicheAnalysis" },
        feature: features.BASIC,
      });
      menuItems.push({
        label: "Products",
        icon: "shopping-bag",
        to: { name: "Product" },
        feature: features.BASIC,
      });
      menuItems.push({
        label: "Listing Builder",
        icon: "pencil-alt",
        to: { name: "ListingBuilder" },
        feature: features.BASIC,
      });
      menuItems.push({
        label: "Cerebro",
        icon: "list-ol",
        to: { name: "Cerebro" },
        feature: features.BASIC,
      });
      menuItems.push({
        separator: true,
      });
      menuItems.push({
        label: "Setting",
        icon: "gear",
        to: { name: "Setting" },
        feature: features.BASIC,
      });

      return menuItems;
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) {
      this.addClass(document.body, "body-overflow-hidden");
    } else {
      this.removeClass(document.body, "body-overflow-hidden");
    }
  },
  components: {
    ConfirmDialog: ConfirmDialog,
    Toast: Toast,
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
