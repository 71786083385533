<template>
  <div class="layout-topbar">
    <router-link
      :to="{ name: 'ProductTrendResearch' }"
      class="layout-topbar-logo"
    >
      <img src="@/assets/logo.png" alt="">
      <span class="logo">DataPlus</span>
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <font-awesome-icon
        :icon="sidebarVisible ? 'circle-arrow-left' : 'circle-arrow-right'"
        size="2x"
        fixed-width
        class="icon"
      />
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <Button
          class="p-link layout-topbar-profile-button"
          type="button"
          @click="onTopbarToggle"
        >
          <img :src="userPhotoUrl" alt="user photo" />
          <div class="user-info">
            <span class="team-name">{{ teamName }}</span>
            <span class="user-name">{{ userName }}</span>
          </div>
          <div class="wrap-caret-down">
            <font-awesome-icon icon="caret-down" size="lg" fixed-width />
          </div>
        </Button>
        <TieredMenu ref="menu" :model="menuItems" :popup="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, computed } from "vue";
import Button from "primevue/button";
import TieredMenu from "primevue/tieredmenu";

import { useConfirm } from "primevue/useconfirm";

import store from "@/store";

import { authLogout } from "@/firebase/auth.js";
import { useRouter } from "vue-router";

import TeamService from "@/services/team_service";

export default defineComponent({
  name: "AppTopBar",
  components: {
    Button,
    TieredMenu,
  },
  props: {
    sidebarVisible: Boolean,
  },

  emits: ["menu-toggle", "topbar-menu-toggle"],

  setup(_props, { emit }) {
    onMounted(() => {
      store.dispatch("fetchTeamAndTeams");
    });

    const menu = ref();
    const router = useRouter();
    const confirm = useConfirm();
    const state = reactive({
      items: [],
    });
    const teamName = computed(() => store.getters.teamName);
    const userName = computed(() => store.getters.userName);
    const userPhotoUrl = computed(() => store.getters.photoUrl);
    const menuItems = computed(() => {
      let items = store.getters.teams.map((team) => ({
        label: team.name,
        icon: "pi pi-fw pi-users",
        command: () => {
          dialogToUpdateTeam(team.uuid);
        },
      }));
      items.push(
        { separator: true },
        {
          label: "Logout",
          icon: "pi pi-fw pi-power-off",
          command: () => {
            logout();
          },
        }
      );
      return items;
    });

    const logout = () => {
      authLogout(() => {
        router.push("/users/login");
      });
    };

    const dialogToUpdateTeam = (uuid) => {
      if (uuid === store.getters.teamUuid) {
        return;
      }
      confirm.require({
        message: "It will reload the page, are you sure to change the team?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          updateTeam(uuid);
        },
      });
    };

    const updateTeam = (uuid) => {
      TeamService.update_team(uuid)
        .then(() => {
          store.dispatch("fetchTeam");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          router.go(0);
        });
    };

    const onTopbarToggle = (event) => {
      menu.value.toggle(event);
    };

    const onMenuToggle = (event) => {
      emit("menu-toggle", event);
    };

    const onTopbarMenuToggle = (event) => {
      emit("topbar-menu-toggle", event);
    };

    return {
      state,
      teamName,
      userName,
      userPhotoUrl,
      onTopbarToggle,
      onMenuToggle,
      onTopbarMenuToggle,
      menu,
      menuItems,
    };
  },
});
</script>

<style scoped>
.logo {
  color: #243746;
  font-weight: 500;
  font-size: 1.5rem;
}
</style>
